
import { defineComponent } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'

export default defineComponent({
  components: {
    DetailsHero,
    ChatsSettingsPage,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Bring your own CPaaS' },
    ]
    const tabsRouter = [
      {
        name: 'Providers',
        to: { name: 'base.chats.settings.byoc.providers' },
      },
      {
        name: 'Numbers',
        to: { name: 'base.chats.settings.byoc.numbers' },
      },
      {
        name: 'Sender IDs',
        to: { name: 'base.chats.settings.byoc.senderIDs' },
      },
    ]

    return {
      breadcrumbs,
      tabsRouter,
    }
  },
})
