import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_chats_settings_page = _resolveComponent("chats-settings-page")!

  return (_openBlock(), _createBlock(_component_chats_settings_page, { breadcrumbs: _ctx.breadcrumbs }, {
    "page-content-white": _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Bring your own CPaaS",
        class: "mb-4",
        icon: "tmi-operation-path",
        tabs: _ctx.tabsRouter
      }, null, 8, ["tabs"])
    ]),
    _: 1
  }, 8, ["breadcrumbs"]))
}